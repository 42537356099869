import React, { useEffect } from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);


  return (
    <div className="privacy-policy-container">
      <h1>Adatvédelmi Irányelvek</h1>
      <div className="privacy-policy-content">
        <p>A weboldalt a NovaWebWork üzemelteti, amely nagyon komolyan veszi az Ön adatainak védelmét, és minden erőfeszítést megtett annak érdekében, hogy a weboldal biztonságos és megbízható legyen. Az Önről szóló információkat nem gyűjtjük, hacsak Ön nem dönt úgy, hogy megadja azokat nekünk.</p>

        <h2>Hogyan használjuk az Ön információit</h2>
        <p>Ez az adatvédelmi irányelv tájékoztatja Önt arról, hogy mire számíthat, amikor a NovaWebWork személyes információkat gyűjt. Ez vonatkozik az általunk gyűjtött információkra:</p>
        <ul>
          <li>Az Ön által a NowaWebWork.com oldalon kitöltött űrlapokon megadott információk</li>
          <li>Ha kapcsolatba lép velünk, megőrizhetjük a levelezés másolatát</li>
          <li>A weboldal használata során Önnek releváns személyes és pénzügyi információkat kell megadnia</li>
        </ul>

        <p>A NowaWebWork rögzítheti az Önt érdeklő termékeket és szolgáltatásokat, valamint figyelheti a webhely forgalmi mintáit és használatát. A személyes és pénzügyi információkat továbbíthatjuk a releváns Equity Release tanácsadónak, ahol ezt egyértelműen jelezzük a weboldalon.</p>

        <h2>16 év alatti felhasználók</h2>
        <p>Ha Ön tizenhat [16] éves vagy annál fiatalabb, kérjük, minden esetben kérje szülője/gondviselője engedélyét, mielőtt személyes adatokat ad meg a weboldalaknak. Az engedély nélküli felhasználók nem adhatnak meg nekünk személyes adatokat. Ha tudomásunkra jut, hogy Ön tizenhat [16] év alatti, és megpróbált vagy már megadott személyes adatokat a weboldalakon a szülő/gondviselő engedélye nélkül, nem fogadjuk el ezeket az információkat, és lépéseket teszünk az ilyen információk eltávolítására nyilvántartásainkból. Ez magában foglalhatja, hogy hozzáférünk és ellenőrizzük az Ön életkorát és egyéb releváns adatait.</p>

        <h2>Az Ön által megadott információk</h2>
        <p>Ön vállalja, hogy minden Ön által megadott információ pontos. Az információk feldolgozásához a weboldal használata során Önnek releváns személyes adatokat kell megadnia. Ez magában foglalhat olyan adatokat, mint a neve, életkora, e-mail címe, postai címe, telefonszáma, születési dátuma, otthona értéke, fennálló jelzáloghitel-egyenlege és bármely más releváns információ.</p>

        <h2>Adatfeldolgozás és -tárolás</h2>
        <p>A NowaWebWork az Ön adatait, amelyek közül néhány érzékeny lehet, bizalmasan továbbítja feldolgozásra más vállalatok és közvetítők számára. Az Öntől gyűjtött személyes adatokat az Európai Gazdasági Térségen (EGT) belül gyűjtjük, dolgozzuk fel és tároljuk. Személyes adatainak benyújtásával Ön hozzájárul ehhez az átadáshoz, tároláshoz vagy feldolgozáshoz. Szigorú biztonsági eljárásokat követünk a személyes adatok tárolása és közzététele során, amelyeket Ön ezen a weboldalon keresztül adott meg nekünk. Minden ésszerű lépést megteszünk annak érdekében, hogy személyes adatai biztonságban maradjanak.</p>

        <h2>Az Ön személyes adatainak felhasználása</h2>
        <p>Mi vagy a nevünkben eljáró harmadik felek a következő módokon használhatjuk fel az Ön személyes adatait:</p>
        <ul>
          <li>Hogy információt nyújtsunk Önnek az Önt érdeklő árukról és szolgáltatásokról, és közvetlenül kapcsolatba léphetünk Önnel e-mailben és/vagy telefonon, ha ehhez hozzájárult</li>
          <li>Annak biztosítására, hogy weboldalunk tartalma a lehető leghatékonyabb módon jelenjen meg Ön és számítógépe számára</li>
          <li>Az Alliance Group vagy más releváns szolgáltatók e-mailben, SMS-ben és/vagy telefonon fogják felvenni Önnel a kapcsolatot az egészségbiztosítási érdeklődésével kapcsolatban</li>
          <li>Ahol szükséges, hogy megfeleljünk bármely alkalmazandó jogszabálynak vagy megvédjük magunkat vagy felhasználóinkat (beleértve az információcserét más vállalatokkal csalás elleni védekezés céljából)</li>
          <li>Abban az esetben, ha eladjuk vállalkozásunkat, amikor is közzétehetjük az Ön személyes adatait vállalkozásunk leendő vevője számára</li>
        </ul>

        <h2>Látogatók a weboldalunkon</h2>
        <p>Amikor valaki meglátogatja a NowaWebWork.com oldalt, harmadik fél szolgáltatását, a Google Analytics-et használjuk a standard internetes naplózási információk és a látogatói viselkedési minták részleteinek gyűjtésére. Ezt azért tesszük, hogy megtudunk olyan dolgokat, mint például a látogatók száma a webhely különböző részeiben. Ez az információ csak olyan módon kerül feldolgozásra, amely nem azonosít senkit. Nem teszünk, és nem engedjük a Google-nak, hogy kísérletet tegyen a weboldalunkat látogatók személyazonosságának megállapítására. Ha személyazonosításra alkalmas információkat akarunk gyűjteni weboldalunkon keresztül, nyíltan közöljük ezt. Egyértelművé tesszük, amikor személyes adatokat gyűjtünk, és elmagyarázzuk, hogy mit szándékozunk tenni vele. Ha szeretne több információt erről a gyakorlatról és arról, hogy milyen választási lehetőségei vannak arra, hogy a Google ne használja fel ezt az információt, kérjük, ellenőrizze a Google adatvédelmi irányelvek oldalát.</p>

        <h2>Sütik használata a NowaWebWork által</h2>
        <p>A weboldal sütiket használ, hogy segítse a NowaWebWork-öt a weboldal használatának nyomon követésében és hasznosabbá tételében a felhasználók számára. A sütik olyan eszközök, amelyek segítségével az Ön internethasználatával kapcsolatos információkat rögzítjük egy merevlemezen, és mi arra használjuk, hogy javítsuk a weboldalt és az Önnek elérhető szolgáltatásokat/termékeket.</p>
        <p>A sütik kis szöveges fájlok, amelyeket az Ön által meglátogatott weboldalak helyeznek el számítógépén. Széles körben használják őket a weboldalak működtetésére vagy hatékonyabb működésre, valamint információk szolgáltatására a webhely tulajdonosai számára.</p>
        <p>Ezeket a sütiket arra használjuk, hogy információkat gyűjtsünk arról, hogyan használják a látogatók weboldalunkat. Az információkat jelentések összeállítására és a weboldal fejlesztésére használjuk. A sütik anonim formában gyűjtenek információkat, beleértve a weboldal és blog látogatóinak számát, honnan érkeztek a látogatók a weboldalra és mely oldalakat látogatták meg. Ha Ön nem szeretné, hogy a NowaWebWork sütiket használjon, amikor meglátogatja a weboldalt, beállíthatja számítógépét úgy, hogy ne fogadja el a sütiket. Ha nehézségekbe ütközik ennek során, számítógépe súgófájljának lépésről lépésre útmutatót kell adnia.</p>

        <h2>Biztonság és teljesítmény</h2>
        <p>A NowaWebWork harmadik fél szolgáltatását használja a NowaWebWork.com biztonságának és teljesítményének fenntartásához. E szolgáltatás nyújtásához feldolgozza a weboldalunkat látogatók IP-címeit.</p>
        <p>A NowaWebWork minden erőfeszítést megtett annak érdekében, hogy a weboldal biztonságos és megbízható legyen. Minden releváns és megfelelő óvintézkedést és biztonsági intézkedést bevezettünk az információk védelme érdekében.</p>
        <p>A NowaWebWork nem vállal felelősséget a weboldalon keresztül továbbított személyes információk biztonságáért.</p>

        <h2>Közösségi médián keresztül kapcsolatba lépő személyek</h2>
        <p>Ha privát vagy közvetlen üzenetet küld nekünk közösségi médián keresztül, az üzenetet a közösségi média szolgáltatók tárolják. Kérjük, ellenőrizze a közösségi média platformok Felhasználási feltételeit és Adatvédelmi irányelveit. Ezeket nem osztjuk meg más szervezetekkel.</p>

        <h2>E-mailen kapcsolatba lépő személyek</h2>
        <p>Bármely nekünk küldött e-mailt, beleértve minden csatolmányt, figyelemmel kísérhetünk és felhasználhatunk biztonsági okokból és az irodai szabályzatnak való megfelelés ellenőrzése céljából. E-mail figyelő vagy blokkoló szoftvert is használhatunk. Kérjük, vegye figyelembe, hogy Ön felelős azért, hogy bármely nekünk küldött e-mail a törvény keretein belül legyen.</p>

        <h2>Panaszt tevő személyek</h2>
        <p>Amikor panaszt kapunk egy személytől, létrehozunk egy fájlt, amely tartalmazza a panasz részleteit. Ez általában tartalmazza a panaszos személyazonosságát és a panaszban érintett más személyek adatait.</p>
        <p>Az általunk gyűjtött személyes adatokat csak a panasz feldolgozására és az általunk nyújtott szolgáltatás szintjének ellenőrzésére használjuk.</p>
        <p>Általában közzé kell tennünk a panaszos személyazonosságát annak, akiről a panasz szól. Ez elkerülhetetlen, például amikor egy személy adatainak pontossága vitatott.</p>
        <p>Általában közzé kell tennünk a panasz részleteit szakmai felelősségbiztosítási alkuszunk és biztosítónk számára.</p>
        <p>Előfordulhat, hogy a fájlunk másolatát át kell adnunk a Pénzügyi Ombudsmannak, ha egy döntőbírónak kell megvizsgálnia a panaszügyet.</p>
        <p>Kötelesek vagyunk közzétenni a szabályozó hatóságok számára, akik által engedélyezést kaptunk, a weboldalunkon már szereplő szabályozói információkat, magas szintű analitikai adatokat a beérkezett panaszok számáról és jellegéről. Ezek az adatok nem tartalmaznak neveket vagy olyan részleteket, amelyek azonosítják az adott érintettet.</p>
        <p>A panaszfájlokban található személyes adatokat megőrizzük adatmegőrzési szabályzatunknak megfelelően. Ez azt jelenti, hogy a panaszra vonatkozó információkat a lezárástól számított 6 évig megőrizzük. Biztonságos környezetben tároljuk, és a hozzáférést a "szükséges ismeret" elve alapján korlátozzuk.</p>

        <h2>Szolgáltatásainkat igénybe vevő személyek</h2>
        <p>A NowaWebWork különféle szolgáltatásokat nyújt az érintetteknek. Az érintett és más források által szolgáltatott adatokat dolgozzuk fel annak érdekében, hogy megfelelő termékeket és szolgáltatásokat nyújtsunk az érintetteknek.</p>

        <h2>Az Ön adatvédelmi jogai az Általános Adatvédelmi Rendelet (GDPR) alapján</h2>
        <p>Az Ön jogai a GDPR alapján a következők:</p>
        <ul>
          <li>A hozzáférés, frissítés vagy törlés joga a rólunk tárolt információkhoz. Amikor csak lehetséges, közvetlenül hozzáférhet, frissítheti vagy kérheti személyes adatainak törlését a fiókbeállítások részen belül. Ha Ön nem tudja végrehajtani ezeket a műveleteket, kérjük, lépjen kapcsolatba velünk, hogy segíthessünk.</li>
          <li>A helyesbítés joga. Önnek joga van ahhoz, hogy helyesbítse az információit, ha azok pontatlanok vagy hiányosak.</li>
          <li>A tiltakozás joga. Önnek joga van tiltakozni személyes adatainak általunk történő feldolgozása ellen.</li>
          <li>A korlátozás joga. Önnek joga van kérni, hogy korlátozzuk személyes adatainak feldolgozását.</li>
          <li>Az adathordozhatósághoz való jog. Önnek joga van ahhoz, hogy megkapja a nálunk lévő információk másolatát strukturált, géppel olvasható és általánosan használt formátumban.</li>
          <li>A hozzájárulás visszavonásának joga. Önnek joga van bármikor visszavonni a hozzájárulását, ha a NowaWebWork az Ön hozzájárulására támaszkodott személyes adatainak feldolgozásához.</li>
        </ul>
        <p>Ha szeretne tájékoztatást kapni arról, hogy milyen személyes adatokat tárolunk Önről, és ha szeretné, hogy azokat eltávolítsuk rendszereinkből, vagy ha szeretne élni bármely más, fent említett jogával, kérjük, lépjen kapcsolatba velünk. Felhívjuk figyelmét, hogy kérhetjük Öntől személyazonosságának igazolását, mielőtt válaszolnánk az ilyen kérésekre.</p>
        <p>Önnek joga van panaszt tenni egy adatvédelmi hatóságnál személyes adatainak általunk történő gyűjtésével és felhasználásával kapcsolatban. További információért kérjük, lépjen kapcsolatba a helyi adatvédelmi hatósággal az Európai Gazdasági Térségben (EGT).</p>

        <h2>Hogyan léphet kapcsolatba az adatvédelmi tisztviselővel (DPO)</h2>
        <p>Jelenleg vállalkozásunk mérete és adatfeldolgozási tevékenységeink jellege miatt nem szükséges adatvédelmi tisztviselőt (DPO) kinevezni. Ha a jövőben adatvédelmi tisztviselőt nevezünk ki, frissítjük Adatvédelmi irányelveinket a releváns kapcsolattartási információkkal.</p>

        <h2>Adatkezelő</h2>
        <p>A NowaWebWork az adatkezelő. A NowaWebWork az EGT-n belül végez adatfeldolgozást.</p>

        <h2>Az érintettek törléshez való joga (Az elfeledtetéshez való jog)</h2>
        <p>Az érintettek értesíthetik a NowaWebWork-öt, ha élni kívánnak törléshez való jogukkal. A hozzájárulás ilyen visszavonása nem érinti a hozzájáruláson alapuló, a visszavonás előtti adatkezelés jogszerűségét. Azokat az érintetteket, akik élnek ezzel a joggal, eltávolítjuk minden marketing vagy jövőbeli kapcsolatfelvételből.</p>
        <p>A NowaWebWork megőrizheti a személyes adatok másolatát, ha az adatkezelés szükséges jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez.</p>

        <h2>A helyesbítéshez való jog</h2>
        <p>A NowaWebWork törekszik arra, hogy a pontatlan vagy hiányos adatokat helyesbítse. Az érintetteknek joguk van a helyesbítéshez.</p>

        <h2>A közvetlen üzletszerzés céljából történő adatkezelés elleni tiltakozáshoz való jog</h2>
        <p>Az érintettek értesíthetik a NowaWebWork-öt, ha élni kívánnak azzal a jogukkal, hogy eltávolítsák őket bármilyen közvetlen üzletszerzési tevékenységből. A NowaWebWork továbbra is kapcsolatba léphet az érintettel a nyújtott politikákkal és szolgáltatásokkal kapcsolatos szerződéses kötelezettségek teljesítése érdekében.</p>

        <h2>Személyes adatok közzététele</h2>
        <p>Sok esetben nem tesszük közzé a személyes adatokat hozzájárulás nélkül. Amikor azonban panaszt vizsgálunk ki, például, meg kell osztanunk a személyes adatokat az érintett szervezettel és más releváns szervekkel.</p>

        <h2>Az adatvédelmi nyilatkozat változásai</h2>
        <p>Rendszeresen felülvizsgáljuk adatvédelmi nyilatkozatunkat. Ez az adatvédelmi nyilatkozat utoljára 2023. június 2-án került frissítésre.</p>

        <h2>Hogyan léphet kapcsolatba velünk</h2>
        <p>Ha nem ért egyet ezzel az adatvédelmi irányelvvel, kérjük, ne adja meg személyes adatait weboldalunkon. Ha további információt szeretne kérni Adatvédelmi irányelvünkről, kérjük, lépjen kapcsolatba velünk e-mailben vagy írásban az alábbi címen:</p>

        <h3>Kapcsolattartási információ:</h3>
        <p>Telefonszám: [+36306659958]</p>
        <p>E-mail: [tkaracsonyi701@gmail.com]</p>
        <p>Weboldal: [NovaWebWork.com]</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;