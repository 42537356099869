import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// ScrollToTop komponens
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

// ContactForm komponens
const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [category, setCategory] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch('https://formspree.io/f/manwqega', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          category,
          message
        }),
      });
     
      if (response.ok) {
        alert(`Köszönjük üzenetét, ${name}! Hamarosan felvesszük Önnel a kapcsolatot.`);
        setName('');
        setEmail('');
        setMessage('');
        setCategory('');
      } else {
        throw new Error('Hiba történt az üzenet küldése során');
      }
    } catch (error) {
      console.error('Hiba:', error);
      alert('Sajnáljuk, hiba történt az üzenet küldése során. Kérjük, próbálja újra később.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <div className="form-group">
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="form-input"
          placeholder=" "
        />
        <label htmlFor="name" className="form-label">Név</label>
      </div>
      <div className="form-group">
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="form-input"
          placeholder=" "
        />
        <label htmlFor="email" className="form-label">E-mail cím</label>
      </div>
      <div className="category-buttons">
        {['Weboldal Fejlesztés', 'E-commerce Megoldások', 'SEO Optimalizálás', 'Weboldal Karbantartás'].map((cat, index) => (
          <button
            key={index}
            type="button"
            className={`category-button ${category === cat ? 'active' : ''}`}
            onClick={() => setCategory(cat)}
          >
            {cat}
          </button>
        ))}
      </div>
      <div className="form-group">
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
          className="form-input"
          placeholder=" "
        ></textarea>
        <label htmlFor="message" className="form-label">Üzenet</label>
      </div>
      <button type="submit" className="submit-button" disabled={isSubmitting}>
        {isSubmitting ? 'Küldés...' : 'Küldés'}
      </button>
    </form>
  );
};

export { ScrollToTop, ContactForm };