import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
`;

const Loader = styled.div`
  width: 100px;
  aspect-ratio: 1;
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  background: #000000;
  filter: blur(3px) contrast(7);
  &:before {
    content: "";
    margin: auto;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #00008B;
    background: currentColor;
    box-shadow: -30px 0, 30px 0, 0 30px, 0 -30px;
    animation: l6 1s infinite alternate;
  }
  @keyframes l6 {
    90%, 100% {
      box-shadow: -10px 0, 10px 0, 0 10px, 0 -10px;
      transform: rotate(180deg)
    }
  }
`;

const MobileVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover; // változtatás cover-ről contain-re
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function SplineScene() {
  const [isContentReady, setIsContentReady] = useState(false);
  const [isSplineLoaded, setIsSplineLoaded] = useState(false);
  const [Spline, setSpline] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 768;
      console.log('Window width:', window.innerWidth, 'Is mobile:', newIsMobile);
      setIsMobile(newIsMobile);
    };
    
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const checkContentReady = () => {
    const allImagesLoaded = Array.from(document.images).every((img) => img.complete);
    console.log('All images loaded:', allImagesLoaded);

    const sections = [
      '.hero-section',
      '.about-us-section',
      '.problem-solution-section',
      '.benefits-section',
      '.services-section',
      '.faq-section',
      '.pricing-section',
      '.contact-section'
    ];

    const sectionStatuses = sections.map(selector => {
      const section = document.querySelector(selector);
      if (!section) {
        console.log(`Section ${selector} not found`);
        return false;
      }
      const hasContent = section.innerText.trim().length > 0;
      const sectionImagesLoaded = Array.from(section.querySelectorAll('img')).every(img => img.complete);
      const sectionVideosLoaded = Array.from(section.querySelectorAll('video')).every(video => video.readyState >= 2);
      console.log(`Section ${selector}: Content - ${hasContent}, Images - ${sectionImagesLoaded}, Videos - ${sectionVideosLoaded}`);
      return hasContent && sectionImagesLoaded && sectionVideosLoaded;
    });

    const allSectionsLoaded = sectionStatuses.every(status => status);
    console.log('All sections loaded:', allSectionsLoaded);

    const interactiveElementsReady = document.querySelectorAll('button, input, form').length > 0;
    console.log('Interactive elements ready:', interactiveElementsReady);

    return allImagesLoaded && allSectionsLoaded && interactiveElementsReady;
  };

  useEffect(() => {
    let checkAttempts = 0;
    const maxAttempts = 10;

    const onContentReady = () => {
      if (checkContentReady()) {
        console.log('All content is ready');
        setIsContentReady(true);
      } else if (checkAttempts < maxAttempts) {
        checkAttempts++;
        console.log(`Content not ready, attempt ${checkAttempts} of ${maxAttempts}`);
        setTimeout(onContentReady, 1000);
      } else {
        console.log('Max attempts reached, forcing content ready');
        setIsContentReady(true);
      }
    };

    if (document.readyState === 'complete') {
      onContentReady();
    } else {
      window.addEventListener('load', onContentReady);
    }

    return () => {
      window.removeEventListener('load', onContentReady);
    };
  }, []);

  useEffect(() => {
    console.log('Content ready:', isContentReady);
    console.log('Is mobile:', isMobile);
    if (isContentReady && !isMobile) {
      console.log('Attempting to load Spline');
      import('@splinetool/react-spline').then(module => {
        console.log('Spline module loaded');
        setSpline(() => module.default);
      }).catch(error => {
        console.error('Error loading Spline:', error);
      });
    }
  }, [isContentReady, isMobile]);

  const handleSplineLoad = () => {
    console.log('Spline scene loaded successfully');
    setIsSplineLoaded(true);
  };

  console.log('Is mobile:', isMobile); // Debugging célból
  console.log('Rendering, isMobile:', isMobile);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      {isMobile ? (
        <MobileVideo autoPlay loop muted playsInline>
          <source src="/images/robot3d.webm.webm" type="video/webm" />
          Your browser does not support the video tag.
        </MobileVideo>
      ) : (
        <>
          {console.log('Spline loaded:', isSplineLoaded, 'Spline component:', Spline)}
          {(!isSplineLoaded || !Spline) && (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
          {Spline && (
            <Spline
              scene="https://prod.spline.design/tk8OG3PFfbyHxLfK/scene.splinecode"
              onLoad={handleSplineLoad}
              style={{ visibility: isSplineLoaded ? 'visible' : 'hidden' }}
            />
          )}
        </>
      )}
    </div>
  );
}