import React, { useEffect } from 'react';
import './PrivacyPolicy.css';

const TermsOfService = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


  return (
    <div className="terms-of-service-container">
      <h1>Felhasználási Feltételek</h1>
      <div className="terms-of-service-content">
        <p>Az alábbiakban részletezett felhasználási feltételek szabályozzák a NovaWebWork használatát. Ha Ön használja a NovaWebWork weboldalt, akkor elfogadja, hogy ezek a felhasználási feltételek kötelezőek Önre nézve. Ha nem fogadja el bármelyik itt leírt feltételt, akkor nem szabad használnia a weboldalt. A weboldal használatával Ön kijelenti, hogy 18 éves vagy annál idősebb.</p>

        <h2>A NovaWebWork</h2>
        <p>A NovaWebWork egy online információforrás az egészségbiztosításokról. A NovaWebWork ingyenes egészségbiztosítási ajánlatkérési szolgáltatást nyújt. A tulajdonosa a NovaWebWork.</p>

        <h2>A feltételek módosítása</h2>
        <p>Ezek a felhasználási feltételek alkalmanként módosulhatnak, és a közzététel után ezek a módosítások azonnal hatályba lépnek.</p>

        <h2>A NovaWebWork felelősségének korlátozása</h2>
        <p>A NovaWebWork nem vállal garanciát és nem felelős a következőkért:</p>
        <ul>
          <li>A NovaWebWork weboldalon megjelenő harmadik fél által készített tartalom pontossága (ez magában foglalja azt a tartalmat is, amelyet a NovaWebWork harmadik fél által készített tartalomként azonosít vagy sem)</li>
          <li>A NovaWebWork weboldalon esetlegesen szereplő harmadik fél szolgáltatásainak minősége</li>
          <li>Bármely harmadik fél weboldal tartalma, amely a weboldalról linkel</li>
        </ul>
        <p>A weboldalon szereplő vagy a weboldalról linkelt pénzügyi információk nem tekinthetők a NovaWebWork által nyújtott pénzügyi tanácsadásnak.</p>
        <p>A NovaWebWork nem felelős semmilyen problémáért, amely a weboldalról elérhető harmadik felekkel való kapcsolatfelvételből vagy kommunikációból ered.</p>

        <h2>Harmadik felek feltételei</h2>
        <p>Bármely harmadik fél felhasználási feltételeinek elfogadása teljesen elkülönül a NovaWebWork használatakor elfogadott felhasználási feltételektől. Javasoljuk, hogy amikor egy harmadik fél felhasználási feltételeit fogadja el, kérjen független tanácsot. Továbbá, mielőtt bármilyen megállapodást kötne egy harmadik féllel, javasoljuk, hogy olvassa el a harmadik fél felhasználási feltételeit. A NovaWebWork nem felelős semmilyen veszteségért vagy kárért, amely a harmadik fél tanácsából vagy a NovaWebWork-ön keresztül ajánlatot kérő személy és a harmadik fél közötti szerződésből ered.</p>

        <h2>Díjazás</h2>
        <p>Amennyiben a NovaWebWork díjazásban részesül az Ön által a weboldalról elérhető harmadik fél szolgáltatások használata eredményeként, ez semmilyen módon nem jelent kizárólagos partnerséget vagy ügynöki kapcsolatot az adott harmadik féllel. A NovaWebWork ingyenes szolgáltatást nyújt.</p>

        <h2>Szellemi tulajdon</h2>
        <p>A weboldalon közzétett összes anyag szerzői joga és szellemi tulajdonjoga (IP); weboldal dizájn; vállalati identitás; szöveg és grafika; és minden szoftver összeállítás, forráskód és szoftver a NovaWebWork tulajdonát képezi. Minden jog fenntartva.</p>
        <p>Engedélyt kell szerezni a weboldalról származó anyag sokszorosítása vagy újraelosztása előtt. Egyetlen nyomtatott példány készíthető személyes, nem kereskedelmi célú felhasználásra. A weboldal tartalmának adaptációja vagy módosítása, valamint a jogosulatlan linkek szintén nem megengedettek.</p>
        <p>A NovaWebWork tulajdonolja a NovaWebWork jogait. Minden más vállalatnév és termék a megfelelő tulajdonosok tulajdonát képezi.</p>
        <p>A weboldalról származó információkat nem szabad illegális vagy erkölcstelen célokra felhasználni.</p>

        <h2>Felelősségi kötelezettségek</h2>
        <p>A NovaWebWork mindent megtesz annak érdekében, hogy a weboldalon szereplő adatok pontosak legyenek, és törekszik arra, hogy azonnal kijavítsa a pontatlanságokat, amint a vállalat tudomást szerez róluk. A törvény által megengedett mértékben a NovaWebWork nem felelős a weboldalon közzétett pontatlanságokért.</p>
        <p>A NovaWebWork nem ellenőrzi, nem figyeli és nem támogatja a weboldalra beküldött harmadik fél információkat, és mint ilyen, ezek az információk pontatlanok lehetnek. A NovaWebWork nem ellenőrzi, nem figyeli és nem támogatja a harmadik felektől gyűjtött adatokat vagy ajánlatokat, amelyeket a keresési eredmények oldalán mutatunk be Önnek.</p>
        <p>A NovaWebWork nem felelős semmilyen káros hatásért a technológiára, amely a weboldal használatából eredhet, és a NovaWebWork nem ad garanciát arra, hogy a weboldal vírusmentes. Ezenkívül a NovaWebWork nem vállal felelősséget semmilyen veszteségért vagy igényért, amely a weboldal elérhetetlenségéből, vagy a weboldal használatából, illetve a weboldalon közzétett adatokra való támaszkodásból ered, a törvény által megengedett mértékben.</p>
        <p>A jelen felhasználási feltételekben semmi sem zárja ki vagy korlátozza a felelősségünket csalás esetén, vagy zárja ki vagy korlátozza a felelősségünket a FSMA (Financial Services and Markets Act 2000) alapján.</p>
        <p>A NovaWebWork kizárja a felelősséget bármilyen jellegű veszteségért vagy kárért, beleértve, de nem korlátozva, bármilyen közvetlen, közvetett, fegyelmi vagy jelentős veszteséget. A NovaWebWork-nek nincs kötelezettsége pénzbeli kártérítés fizetésére.</p>

        <h2>A megállapodás megszűnése</h2>
        <p>Ha az itt meghatározott felhasználási feltételek bármelyikét megszegik, a NovaWebWork-nek joga van a megállapodás megszüntetésére és a weboldal hozzáférésének és használatának azonnali eltávolítására, törlésére vagy felfüggesztésére.</p>
        <p>Az összes felelősségkizárás, kártalanítás és kizárás, amelyet ezek a felhasználási feltételek tartalmaznak, fennmarad a közöttünk létrejött megállapodás bármilyen okból történő megszűnése után is.</p>
        <p>A NovaWebWork nem felelős a jelen megállapodás szerinti kötelezettségek teljesítésének elmulasztásáért, ha a mulasztás az ellenőrzésén kívül eső eseményekből ered, beleértve, de nem kizárólagosan az internet/telekommunikációs kimaradásokat, tüzet, árvizet, háborút és vis maior eseményeket.</p>

        <h2>Általános információk</h2>
        <p>A weboldalt nem szabad üzleti célokra használni.</p>
        <p>Ha a megállapodás bármely rendelkezését jogellenesnek vagy érvénytelennek nyilvánítják, azt a rendelkezést leválasztottnak kell tekinteni. Adott esetben a megállapodás fennmaradó rendelkezésének érvényességét ez nem érinti.</p>
        <p>Ha a NovaWebWork nem érvényesíti a jelen megállapodás bármely rendelkezését, az nem tekinthető bármely rendelkezés vagy jog lemondásának.</p>
        <p>A weboldal használata és ezek a feltételek Anglia törvényeinek hatálya alá tartoznak, amelyek kizárólagosan szabályozzák az összes fenti engedély, kizárás, licenc és használati feltétel értelmezését, alkalmazását és hatását, és az angol törvényeknek megfelelően kell értelmezni, és a felek az angol bíróságok kizárólagos joghatóságának vetik alá magukat.</p>
      </div>
    </div>
  );
};

export default TermsOfService;