import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import './styles.css';
import { CheckCircle, MessageSquare, Phone, User } from 'lucide-react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import { ScrollToTop } from './components/ScrollToTop';
import { Helmet } from 'react-helmet';
import SplineScene from './SplineScene';

const positionElement = (elementClass) => {
  const element = document.querySelector(elementClass);
  if (!element) return;
  
  const { innerWidth: viewportWidth, innerHeight: viewportHeight } = window;
  const { offsetWidth: elementWidth, offsetHeight: elementHeight } = element;

  const left = (viewportWidth - elementWidth) / 2;
  const top = (viewportHeight - elementHeight) / 2;

  Object.assign(element.style, {
    position: 'fixed',
    left: `${left}px`,
    top: `${top}px`
  });
};

const menuItems = [
  { text: 'Kezdőlap', href: '#kezdolap' },
  { text: 'Rólunk', href: '#rolunk' },
  { text: 'Szolgáltatások', href: '#szolgaltatasok' },
  { text: 'GYIK', href: '#gyik' },
  { text: 'Árak', href: '#arak' },
  { text: 'Kapcsolat', href: '#kapcsolat' }
];

const Card = React.memo(({ id, title, image, description }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const isMobile = window.innerWidth <= 768;

  const handleClick = useCallback(() => {
    setIsFlipped(prev => !prev);
  }, []);

  const highlightText = useMemo(() => (text) => {
    return text.split(/(\*\*.*?\*\*)/).map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return <span key={index} className="highlight">{part.slice(2, -2)}</span>;
      }
      return part;
    });
  }, []);

  return (
    <div className={`card ${isFlipped ? 'flipped' : ''}`} onClick={handleClick}>
      <div className="card-inner">
        <div className="card-front">
          <img src={`${process.env.PUBLIC_URL}/images/${image}`} alt={title} loading="lazy" className="lazy-load" />
          <h3>{title}</h3>
          <p className="card-instruction">{isMobile ? "Koppints" : "Kattints"} a részletekért</p>
        </div>
        <div className="card-back">
          <p>{highlightText(description)}</p>
        </div>
      </div>
    </div>
  );
});

const FAQItem = React.memo(({ question, answer, isOpen, toggleOpen }) => (
  <div className={`faq-item ${isOpen ? 'active' : ''}`}>
    <div className="faq-question" onClick={toggleOpen}>{question}</div>
    <div className="faq-answer">{answer}</div>
  </div>
));

const ServiceCard = React.memo(({ title, description, icon }) => (
  <div className="service-card">
    <div className="service-icon">{icon}</div>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
));

const PricingCard = React.memo(({ title, price, features, isPopular, image, onButtonClick, packageType }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className={`pricing-card ${isPopular ? 'popular' : ''} ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isPopular && <div className="popular-tag">Legnépszerűbb</div>}
      <img src={image} alt={title} className="pricing-card-image lazy-load" loading="lazy" />
      <h3>{title}</h3>
      <div className="price">{price}</div>
      <ul>
        {features.map((feature, index) => (
          <li key={index}>
            <CheckCircle size={18} className="check-icon" />
            {feature}
          </li>
        ))}
      </ul>
      <div className="pricing-card-button-wrapper">
        <button className="cta-button" onClick={() => onButtonClick(packageType)}>Válassza ezt a csomagot</button>
      </div>
    </div>
  );
});

const Header = () => {
  const [activeLink, setActiveLink] = useState('');
  const location = useLocation();
  const [showTooltip, setShowTooltip] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname !== '/') return;
      const sections = document.querySelectorAll('section');
      const scrollPosition = window.scrollY;

      sections.forEach(section => {
        const sectionTop = section.offsetTop - 150;
        const sectionHeight = section.offsetHeight;
        const sectionId = section.getAttribute('id');

        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          setActiveLink(`#${sectionId}`);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(prev => !prev);
  }, []);

  return (
    <header>
      <div className="logo-container">
        <Link to="/" onClick={scrollToTop}>
          <img src="/images/inverted2.webp" alt="NovaWebWork Logo" className="logo" />
        </Link>
      </div>
      <nav className={`header-nav ${isMenuOpen ? 'open' : ''}`}>
        {location.pathname === '/' ? (
          menuItems.map((item, index) => (
            <a
              key={index}
              href={item.href}
              className={activeLink === item.href ? 'active' : ''}
              onClick={() => setIsMenuOpen(false)}
            >
              {item.text}
              <span></span>
            </a>
          ))
        ) : (
          <Link to="/" onClick={() => setIsMenuOpen(false)}>Vissza a főoldalra</Link>
        )}
        <div
          className="phone-icon-container"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <a href="tel:+36306659958" className="phone-icon">
            <Phone size={24} />
          </a>
          {showTooltip && (
            <div className="tooltip">
              +36306659958
            </div>
          )}
        </div>
      </nav>
      <div className="hamburger-menu" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
  );
};

const CTAButton = React.memo(({ onClick, children }) => (
  <div className="cta-button-wrapper" onClick={onClick}>
    <span className="cta-text">{children}</span>
    <span className="cta-arrow">
      <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="arrow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path className="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path className="two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path className="three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.3956030.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
        </g>
      </svg>
    </span>
  </div>
));

const MessageIconPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      positionElement('.popup');
      window.addEventListener('resize', () => positionElement('.popup'));
    }
    return () => window.removeEventListener('resize', () => positionElement('.popup'));
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch('https://formspree.io/f/xkgwgrzy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          message: `Új feliratkozó: ${email}`
        }),
      });
     
      if (response.ok) {
        alert(`Gratulálunk! A 10% kedvezménykódot elküldtük a következő címre: ${email}`);
        setIsOpen(false);
        setEmail('');
      } else {
        throw new Error('Hiba történt a feliratkozás során');
      }
    } catch (error) {
      console.error('Hiba:', error);
      alert('Sajnáljuk, hiba történt a feliratkozás során. Kérjük, próbálja újra később.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="message-icon-container">
      <motion.div
        style={{
          position: 'fixed',
          right: '20px',
          bottom: '20px',
        }}
        whileHover={{ scale: 1.1, rotate: 5 }}
        whileTap={{ scale: 0.9 }}
        animate={{ y: [0, -10, 0] }}
        transition={{ repeat: Infinity, duration: 2 }}
        onClick={() => setIsOpen(true)}
        className="message-icon"
      >
        <MessageSquare size={48} color="#1e90ff" />
      </motion.div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ type: "spring", stiffness: 300, damping: 25 }}
            className="popup"
          >
            <button
              onClick={() => setIsOpen(false)}
              className="popup-close-button"
              aria-label="Bezárás"
            >
              ✕
            </button>
            <h2>Exkluzív Ajánlat!</h2>
            <p>Iratkozzon fel hírlevelünkre most, és azonnal kap 10% kedvezményt következő vásárlására!</p>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Az Ön email címe"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                className="popup-submit-button"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Feldolgozás...' : 'Kérem a kedvezményt'}
              </motion.button>
            </form>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const AboutUsSection = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const storyPoints = [
    "Elkötelezettek vagyunk a webfejlesztés és a kreatív dizájn iránt",
    "Az AI által támogatott fejlesztés több időt biztosít a kreativitás számára",
    "Minden ügyfelünknek egyedi és innovatív megoldásokat kínálunk",
    "Folyamatosan fejlődünk és követjük a legújabb trendeket"
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { x: -20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1
    }
  };

  return (
    <section className="about-us-section parallax scroll-animate" data-speed="0.2" id="rolunk" ref={ref}>
      <div className="about-us-content">
        <div className="about-us-text">
          <h2>Rólam: Szenvedély és Innováció a Webfejlesztésben</h2>
          <p>
            A NovaWebWork szenvedéllyel közelíti meg a webfejlesztést és a kreatív dizájnt.
            Az AI-asszisztált fejlesztés lehetővé teszi számunkra, hogy igazán egyedi,
            figyelemfelkeltő weboldalakat alkossunk, amelyek nem csak vonzóak, de hatékonyak is.
          </p>
          <motion.ul
            className="story-points"
            variants={containerVariants}
            initial="hidden"
            animate={controls}
          >
            {storyPoints.map((point, index) => (
              <motion.li key={index} variants={itemVariants}>
                {point}
              </motion.li>
            ))}
          </motion.ul>
        </div>
        <div className="about-us-spline-container">
          <SplineScene />
        </div>
      </div>
    </section>
  );
};

const ContactForm = ({ selectedPackage, setSelectedPackage }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const mainCategories = [
    { value: 'Weboldal fejlesztés', label: 'Weboldal fejlesztés' },
    { value: 'E-commerce Megoldások', label: 'E-commerce Megoldások' },
    { value: 'SEO Optimalizálás', label: 'SEO Optimalizálás' },
    { value: 'Weboldal Karbantartás', label: 'Weboldal Karbantartás' },
  ];

  const subCategories = [
    { value: 'Alap csomag', label: 'Alap csomag' },
    { value: 'Üzleti csomag', label: 'Üzleti csomag' },
    { value: 'Prémium Csomag', label: 'Prémium Csomag' },
    { value: 'Speciális Csomag', label: 'Speciális Csomag' },
    { value: 'Egyéni igények Csomag', label: 'Egyéni igények Csomag' },
  ];

  useEffect(() => {
    if (selectedPackage) {
      setCategory('Weboldal fejlesztés');
      setSubCategory(selectedPackage);
      setIsDropdownOpen(false);
    }
  }, [selectedPackage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCategoryClick = useCallback((value) => {
    if (value === 'Weboldal fejlesztés') {
      setCategory(value);
      setIsDropdownOpen(prev => !prev);
    } else {
      setCategory(value);
      setSubCategory('');
      setIsDropdownOpen(false);
      setSelectedPackage('');
    }
  }, [setSelectedPackage]);

  const handleSubCategoryClick = useCallback((value) => {
    setSubCategory(value);
    setIsDropdownOpen(false);
    setSelectedPackage(value);
  }, [setSelectedPackage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch('https://formspree.io/f/xkgwgrzy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          message,
          category,
          subCategory,
        }),
      });

      if (response.ok) {
        alert('Köszönjük az üzenetet! Hamarosan felvesszük Önnel a kapcsolatot.');
        setName('');
        setEmail('');
        setMessage('');
        setCategory('');
        setSubCategory('');
        setSelectedPackage('');
      } else {
        throw new Error('Hiba történt az üzenet küldése során');
      }
    } catch (error) {
      console.error('Hiba:', error);
      alert('Sajnáljuk, hiba történt az üzenet küldése során. Kérjük, próbálja újra később.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <div className="form-group">
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="form-input"
          placeholder=" "
        />
        <label htmlFor="name" className="form-label">Név</label>
      </div>
      <div className="form-group">
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="form-input"
          placeholder=" "
        />
        <label htmlFor="email" className="form-label">E-mail</label>
      </div>
      <div className="category-buttons">
        {mainCategories.map((cat) => (
          <div key={cat.value} className="category-button-container">
            <button
              type="button"
              className={`category-button ${category === cat.value ? 'active' : ''}`}
              onClick={() => handleCategoryClick(cat.value)}
              data-category={cat.value}
            >
              {cat.value === 'Weboldal fejlesztés' && subCategory
                ? subCategory
                : cat.label}
            </button>
            {cat.value === 'Weboldal fejlesztés' && (
              <div 
                ref={dropdownRef}
                className={`subcategory-dropdown ${isDropdownOpen ? 'open' : ''}`}
              >
                {subCategories.map((subCat) => (
                  <button
                    key={subCat.value}
                    type="button"
                    className={`subcategory-button ${subCategory === subCat.value ? 'active' : ''}`}
                    onClick={() => handleSubCategoryClick(subCat.value)}
                  >
                    {subCat.label}
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="form-group">
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
          className="form-input"
          placeholder=" "
        ></textarea>
        <label htmlFor="message" className="form-label">Üzenet</label>
      </div>
      <button type="submit" className="submit-button" disabled={isSubmitting}>
        {isSubmitting ? 'Küldés...' : 'Üzenet küldése'}
      </button>
    </form>
  );
};

function App() {
  const [openFAQs, setOpenFAQs] = useState({});
  const heroRef = useRef(null);
  const servicesRef = useRef(null);
  const [selectedPackage, setSelectedPackage] = useState('');

  const toggleFAQ = useCallback((faqId) => {
    setOpenFAQs(prev => ({
      ...prev,
      [faqId]: !prev[faqId]
    }));
  }, []);

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const videoRef = useRef(null);
  
  useEffect(() => {
    const video = videoRef.current;
    
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && video && !isVideoLoaded) {
          video.src = '/images/PSVID2.webm';
          setIsVideoLoaded(true);
        }
      });
    }, options);
  
    if (video) {
      observer.observe(video);
    }
  
    return () => {
      if (video) {
        observer.unobserve(video);
      }
    };
  }, [isVideoLoaded]);

  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;
     
      if (servicesRef.current) {
        const cards = servicesRef.current.querySelectorAll('.service-card');
        cards.forEach((card, index) => {
          const cardTop = card.getBoundingClientRect().top;
          if (cardTop < viewportHeight * 0.75) {
            setTimeout(() => {
              card.style.opacity = '1';
              card.style.transform = 'translateY(0)';
            }, index * 200);
          }
        });
      }

      const sections = document.querySelectorAll('.scroll-animate');
      sections.forEach((section) => {
        const sectionTop = section.getBoundingClientRect().top;
        const sectionHeight = section.offsetHeight;
        const scale = Math.min(Math.max((viewportHeight - sectionTop) / (sectionHeight * 0.5), 0), 1);
        section.style.transform = `scale(${0.9 + 0.1 * scale})`;
        section.style.opacity = 0.5 + 0.5 * scale;
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Google Analytics
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-P17EPREQ3Q`;
    script.async = true;
    document.head.appendChild(script);
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-P17EPREQ3Q');
    };
  }, []);

  useEffect(() => {
    const slider = document.querySelector('.pricing-slider');
    const leftArrow = document.querySelector('.slider-arrow.left');
    const rightArrow = document.querySelector('.slider-arrow.right');
    let currentIndex = 0;
  
    const slideLeft = () => {
      if (currentIndex > 0) {
        currentIndex--;
        slider.style.transform = `translateX(-${currentIndex * 33.33}%)`;
      }
    };
  
    const slideRight = () => {
      if (currentIndex < 1) {
        currentIndex++;
        slider.style.transform = `translateX(-${currentIndex * 33.33}%)`;
      }
    };
  
    leftArrow && leftArrow.addEventListener('click', slideLeft);
    rightArrow && rightArrow.addEventListener('click', slideRight);
  
    return () => {
      leftArrow && leftArrow.removeEventListener('click', slideLeft);
      rightArrow && rightArrow.removeEventListener('click', slideRight);
    };
  }, []);
  
    const faqData = useMemo(() => [
      { id: 1, question: "Milyen típusú weboldalakat készít?", answer: "Sokféle weboldalt készítek, beleértve a vállalati weboldalakat, e-commerce platformokat, portfólió oldalakat és egyedi alkalmazásokat is. Minden projektemet az ügyfél egyedi igényeihez igazítom." },
      { id: 2, question: "Mennyi idő alatt készül el egy weboldal?", answer: "A projekt komplexitásától függően általában 4-8 hét alatt elkészítem a weboldalt. Pontos időbecslést az igények részletes felmérése után tudok adni. Sürgős esetekben gyorsított folyamatot is tudok biztosítani." },
      { id: 3, question: "Mobilbarát lesz a weboldalam?", answer: "Igen, minden általam készített weboldal reszponzív dizájnnal rendelkezik, így tökéletesen működik mobil eszközökön is. A mobil-first megközelítést alkalmazzuk, hogy biztosítsuk a legjobb felhasználói élményt minden eszközön." },
      { id: 4, question: "Segít a weboldal karbantartásában is?", answer: "Igen, kínálok karbantartási és támogatási csomagokat is, hogy weboldala mindig naprakész és biztonságos maradjon. Ezek magukban foglalhatják a rendszeres frissítéseket, biztonsági ellenőrzéseket és tartalmi módosításokat is." },
      { id: 5, question: "Hogyan javítja a weboldal keresőoptimalizálását?", answer: "SEO-barát kódolással, megfelelő meta adatok használatával és teljesítmény optimalizálással segítem a jobb keresőmotor-rangsorolást. Emellett kulcsszókutatást végzek, és segítek a tartalom optimalizálásában is a jobb keresési eredmények elérése érdekében." },
      { id: 6, question: "Milyen fizetési módokat fogad el?", answer: "Elfogadok banki átutalást, PayPal-t és bizonyos esetekben részletfizetési lehetőséget is biztosítok. Nagyobb projekteknél rugalmas fizetési ütemezést tudunk kialakítani az ügyfél igényeinek megfelelően." },
      { id: 7, question: "Van referenciamunkájuk, amit meg tudok nézni?", answer: "Jelenleg a legjobb referenciám ez az oldal, mivel most indultam el a webfejlesztés útján. Még nem voltak ügyfeleim, de elkötelezett vagyok abban, hogy a legjobb munkát végezzem. Remélem, hogy hamarosan az Ön projektje is a referenciáim közé kerül!" }
    ], []);
  
    const pricingData = useMemo(() => [
      {
        title: "Alap Csomag: Személyes vagy Szakmai Bemutatkozó Oldal",
        price: "199.000 Ft-tól",
        features: [
          "Egyoldalas (one-page) reszponzív dizájn",
          "Személyre szabott szekciók",
          "Alapszintű SEO optimalizálás",
          "Kapcsolatfelvételi űrlap",
          "Közösségi média integráció",
          "Mobilbarát megjelenés"
        ],
        image: "/images/price1.webp",
        packageType: "Alap Csomag"
      },
      {
        title: "Üzleti Csomag: Vállalati Bemutatkozó Weboldal",
        price: "299.000 Ft-tól",
        features: [
          "Többoldalas reszponzív weboldal (max. 5 oldal)",
          "Egyedi, professzionális dizájn",
          "Haladó SEO optimalizálás",
          "Tartalomkezelő rendszer integrálása",
          "Blogszekció lehetősége",
          "Egyszerű 3D elem vagy animáció",
          "Google Analytics integráció"
        ],
        isPopular: true,
        image: "/images/price2.webp",
        packageType: "Üzleti Csomag"
      },
      {
        title: "Prémium Csomag: Komplex Portfólió vagy Nonprofit Weboldal",
        price: "399.000 Ft-tól",
        features: [
          "Összetett, többoldalas weboldal (max. 10 oldal)",
          "Teljesen egyedi, interaktív dizájnelemek",
          "Fejlett portfólió galéria vagy projekt bemutató szekció",
          "Haladó SEO és teljesítmény optimalizálás",
          "Egyedi kontaktűrlapok és CTA elemek",
          "Eseménykezelő vagy adománygyűjtő funkció",
          "Alapszintű email marketing integráció"
        ],
        image: "/images/price3.webp",
        packageType: "Prémium Csomag"
      },
      {
        title: "Speciális Csomag: Landing Page és Marketing Weboldal",
        price: "249.000 Ft-tól",
        features: [
          "Magas konverziójú, egyoldalas landing page",
          "A/B tesztelésre alkalmas struktúra",
          "Figyelemfelkeltő animációk és CTA elemek",
          "Lead generáló űrlapok",
          "Integráció marketing automatizációs eszközökkel",
          "Teljesítmény-optimalizált betöltési sebesség"
        ],
        image: "/images/price4.webp",
        packageType: "Speciális Csomag"
      }
    ], []);
  
    const scrollToContact = useCallback((packageType) => {
      const contactSection = document.getElementById('kapcsolat');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
        setSelectedPackage(packageType);
      }
    }, []);
  
    return (
      <Router>
      <Helmet>
        <title>NovaWebWork | Professzionális Weboldalkészítés és SEO Optimalizálás</title>
        <meta name="description" content="Egyedi honlapok és céges weboldalak készítése kedvező áron. SEO optimalizáció és egyszerű weblapkészítés. Kérjen árajánlatot most!" />
        <link rel="canonical" href="https://www.novawebwork.com" />
          <meta name="keywords" content="honlap készítés, weboldalkészítés, seo optimalizáció, reszponzív dizájn, e-commerce megoldások" />
          <meta property="og:title" content="NovaWebWork | Professzionális Weboldalkészítés" />
          <meta property="og:description" content="Egyedi honlapok és céges weboldalak készítése. SEO optimalizáció és modern webfejlesztés." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.novawebwork.com" />
          <meta property="og:image" content="https://www.novawebwork.com/images/og-image.jpg" />
          <link rel="icon" type="image/png" sizes="20x16" href={`${process.env.PUBLIC_URL}/images/inverted2-20x16.webp`} />
          <link rel="icon" type="image/png" sizes="40x32" href={`${process.env.PUBLIC_URL}/images/inverted2-40x32.webp`} />
          <link rel="icon" type="image/png" sizes="60x48" href={`${process.env.PUBLIC_URL}/images/inverted2-60x48.webp`} />
          <link rel="apple-touch-icon" sizes="240x192" href={`${process.env.PUBLIC_URL}/images/inverted2-240x192.webp`} />
        </Helmet>
        <ScrollToTop />
        <div>
          <Header />
          <MessageIconPopup />
   
          <Routes>
            <Route path="/" element={
              <main>
                <section className="hero-section parallax" data-speed="0.5" id="kezdolap" ref={heroRef}>
                  <div className="hero-content">
                    <h1>Professzionális Weboldalkészítés</h1>
                    <h2>Szerezzen több ügyfelet egyedi, modern weboldallal!</h2>
                    <CTAButton onClick={() => scrollToContact('Weboldal fejlesztés')}>
                      Kérjen árajánlatot most!
                    </CTAButton>
                    <ul>
                      <li>Egyedi weboldal tervezés és fejlesztés</li>
                      <li>SEO optimalizálás a jobb láthatóságért</li>
                      <li>Reszponzív dizájn minden eszközre</li>
                      <li>Kedvező weboldal készítés árak</li>
                    </ul>
                  </div>
                </section>
   
                <AboutUsSection />
                <section className="problem-solution-section parallax scroll-animate" data-speed="0.3" id="rolunk">
                <div className="problem-solution-video">
                  <video 
                    ref={videoRef}
                    controls
                    preload="none"
                    poster="/images/video-poster.webp"
                    className={`lazy-video ${isVideoLoaded ? 'loaded' : ''}`}
                  >
                    <source data-src="images/PSVID2.webm" type="video/webm" />
                    Az Ön böngészője nem támogatja a videó lejátszást.
                  </video>
                </div>
                  <div className="problem-solution-content">
                    <h3><span className="highlight">A szolgáltatása kiváló, de nincs elég érdeklődő?</span></h3>
                    <p>A leggyakoribb okok, amiért ez előfordulhat:</p>
                    <ul>
                      <li>A potenciális ügyfelek nem találják meg a weboldalát a keresőkben.</li>
                      <li>A jelenlegi weboldala nem elég meggyőző vagy nehezen használható.</li>
                      <li>A weboldala nem optimalizált mobil eszközökre.</li>
                    </ul>
                    <p><span className="strong">Én professzionális, SEO-optimalizált weboldalakat fejlesztek, amelyek nem csak jól néznek ki, de hatékonyan vonzzák és konvertálják a látogatókat ügyfelekké.</span></p>
                  </div>
                </section>
                <section className="benefits-section parallax scroll-animate" data-speed="0.2" id="szolgaltatasok">
                  <div className="cards-section">
                    <Card
                      id="card1"
                      title="Felhasználóbarát dizájn"
                      image="log1.webp"
                      description="Az általam tervezett weboldalak **felhasználóbarát dizájnnal** rendelkeznek, amely **könnyen érthető és navigálható felületet** kínál. Az egyszerű és letisztult dizájn **növeli az oldalon töltött időt**, ami hosszú távon **magasabb konverziós arányt** eredményez."
                    />
                    <Card
                      id="card2"
                      title="Keresőoptimalizált tartalom"
                      image="log2.webp"
                      description="Weboldalainkat **keresőoptimalizált tartalommal** látom el, hogy **javítsuk a láthatóságot** a keresőmotorok találati listáin. A **megfelelő kulcsszavak használata** és a **jól strukturált tartalom** biztosítja a **jobb találati arányt** és több organikus látogatót vonz az oldalra."
                    />
                    <Card
                      id="card3"
                      title="Gyors betöltési idők"
                      image="log3.webp"
                      description="Az **optimalizált kódolásnak** és a modern technológiák használatának köszönhetően oldalaink **villámgyorsan betöltődnek**, csökkentve a látogatók várakozási idejét. A **gyors oldalbetöltés** nemcsak a **felhasználói élményt javítja**, hanem **pozitív hatással van** a keresőmotorok rangsorolására és a konverziós arányra is."
                    />
                  </div>
                </section>
                <section className="services-section parallax scroll-animate" data-speed="0.4" id="szolgaltatasok" ref={servicesRef}>
      <h2>Weboldal Készítés Szolgáltatásaink</h2>
      <div className="services-container">
        <ServiceCard
          title="Egyedi Weboldal Fejlesztés"
          description="Teljesen személyre szabott, reszponzív weboldalak tervezése és fejlesztése az Ön igényei és márkája szerint. Modern technológiák, gyors betöltési idő."
          icon="🖥️"
        />
        <ServiceCard
          title="E-commerce Megoldások"
          description="Professzionális webáruház készítés, biztonságos fizetési rendszer integrációval. Termékkezelés, készletnyilvántartás, vásárlói fiókok."
          icon="🛒"
        />
        <ServiceCard
          title="SEO Optimalizálás"
          description="Keresőoptimalizálás a jobb láthatóság és magasabb rangsorolás érdekében. Kulcsszókutatás, on-page és off-page SEO stratégiák a weboldalak teljesítményének javításához."
          icon="🔍"
        />
        <ServiceCard
          title="Weboldal Karbantartás és Frissítés"
          description="Folyamatos támogatás, biztonsági frissítések és teljesítmény optimalizálás. Rendszeres tartalom frissítés és technikai segítségnyújtás a weboldal hosszú távú sikeréért."
          icon="🔧"
        />
      </div>
    </section>
                <section className="faq-section parallax scroll-animate" data-speed="0.1" id="gyik">
                  <h2 className="faq-title">Gyakran Ismételt Kérdések</h2>
                  <div className="faq-container">
                    {faqData.map((faq) => (
                      <FAQItem
                        key={faq.id}
                        question={faq.question}
                        answer={faq.answer}
                        isOpen={openFAQs[faq.id]}
                        toggleOpen={() => toggleFAQ(faq.id)}
                      />
                    ))}
                  </div>
                </section>
                <section className="pricing-section parallax scroll-animate" data-speed="0.3" id="arak">
  <h2>Weboldal Készítés Árak és Csomagok</h2>
  <div className="pricing-container">
    <div className="pricing-slider">
      {pricingData.map((plan, index) => (
        <PricingCard
          key={index}
          {...plan}
          onButtonClick={() => scrollToContact(plan.packageType)}
        />
      ))}
    </div>
    <button className="slider-arrow left">&lt;</button>
    <button className="slider-arrow right">&gt;</button>
  </div>
</section>
              <section className="contact-section parallax scroll-animate" data-speed="0.2" id="kapcsolat">
                <h2>Lépjen kapcsolatba velünk</h2>
                <ContactForm selectedPackage={selectedPackage} setSelectedPackage={setSelectedPackage} />
              </section>
            </main>
          } />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
        </Routes>
        <footer>
          <div className="footer-content">
            <p>&copy; 2023 NovaWebWork. Minden jog fenntartva.</p>
            <div className="footer-links">
              <Link to="/privacy-policy">Adatvédelmi Irányelvek</Link>
              <Link to="/terms-of-service">Felhasználási Feltételek</Link>
            </div>
          </div>
          <div className="contact-info">
            <p className="contact-hover">Kapcsolattartó
              <span className="contact-details">
                <User size={16} /> Karácsonyi Tamás<br />
                <Phone size={16} /> +36306659958
              </span>
            </p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;